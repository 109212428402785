@import '../../../../scss/theme-bootstrap';
$button-height: 35px;

.product-brief__sized,
.product-full__sized,
.product-starter__sized {
  &-title {
    padding: 0 20px 20px;
    border-bottom: 1px solid $color-very-light-pink;
    margin-bottom: 20px;
    @include breakpoint($medium-up) {
      padding: 0 0 20px;
    }
    .product-brief & {
      border: none;
      padding: 0;
      margin: 0;
    }
  }
  &-label {
    @include spp_label;
    display: none;
    @include breakpoint($medium-up) {
      display: block;
      padding-top: 11px;
      text-transform: uppercase;
      float: #{$ldirection};
    }
  }
  &-buttons {
    .product-brief & {
      ul.sku-list {
        &__list {
          float: none;
        }
      }
    }
    @include breakpoint($medium-up) {
      .product-full & {
        float: #{$rdirection};
        width: auto;
      }
      .sku-list__item {
        margin-bottom: 0;
      }
    }
    .sku-list {
      &__list {
        float: none;
        @include breakpoint($medium-up) {
          float: #{$rdirection};
        }
        .product-starter-kit & {
          float: none;
        }
        .product-brief & {
          text-align: center;
          padding: 30px 0 0;
        }
        .spp-cross-sells__products & {
          padding: 50px 0 20px;
          min-height: 122px;
          margin-bottom: 10px;
        }
      }
      &__item {
        display: inline-block;
        margin-#{$rdirection}: 6px;
        @include breakpoint($medium-up) {
          @include swap_direction(margin, 0 0 0 6px);
        }
        .product-brief & {
          float: none;
        }
        .button {
          @include button--white;
          border: $color-gray solid 1px;
          color: $color-gray;
          cursor: pointer;
          height: $button-height;
          line-height: 2.3;
          white-space: nowrap;
          padding: 0 20px;
          width: 100%;
          text-transform: none;
          @include breakpoint($medium-up) {
            padding: 0 15px;
          }
          .product-brief & {
            padding: 0 5px;
          }
          &.button--active {
            border-color: $color-black;
            color: $color-black;
            border-width: 2px;
            position: relative;
            top: 1px;
            &:hover {
              color: $color-black;
              @include breakpoint($medium-up) {
                color: $color-white;
              }
            }
          }
          &:hover {
            background-color: $color-white;
            color: $color-black;
            @include breakpoint($medium-up) {
              background-color: $color-black;
              color: $color-white;
            }
          }
        }
      }
      &__button {
        text-transform: none;
        @include breakpoint($medium-up) {
          min-width: 0;
        }
      }
      .product-full & {
        margin: 0;
        padding: 0;
        border: 0;
      }
    }
    .product-brief & {
      min-height: 88px;
    }
  }
}
